body {
    font-family: Space Mono, -apple-system, Roboto, sans-serif, serif;
    margin:0
}

#gradient-canvas {
    width:100%;
    height:100%;
    --gradient-color-1: #994CE5;
    --gradient-color-2: #8B6BAA;
    --gradient-color-3: #663399;
    --gradient-color-4: #BB91E5;
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

.Toastify__toast-body {
    font-family: Space Mono, -apple-system;
    font-size: 12px;
}
